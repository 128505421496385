<template>
  <div class="row">
    <div class="col-lg-12">
<!--      <b-button @click="creat" class="btn btn-success">{{ $t("common.new") }}</b-button>-->
    </div>
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left" rowspan="2">
            {{ $t("informationAboutHeath.list.name") }}
          </th>  
          <th class="text-left" rowspan="2">
            {{ $t("informationAboutHeath.list.birthDate") }}
          </th>
          <th class="text-left" colspan="5">
            {{ $t("informationAboutHeath.list.healthData") }}
          </th>
          <th class="text-left" rowspan="2">
            {{ $t("informationAboutHeath.list.height") }}
          </th>     
          <th class="text-left" rowspan="2">
            {{ $t("informationAboutHeath.list.deskNumber") }}
          </th>
          <th class="text-left" rowspan="2">           
          </th>
        </tr>
        <tr>
          <th class="text-left">
            {{ $t("informationAboutHeath.list.diagnosisOfTheDisease") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutHeath.list.treatmentRecommendations") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutHeath.list.vision") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutHeath.list.hearing") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutHeath.list.medicalPhysicalEduGroup") }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.studentName }}</td>
          <td>{{ item.birthDayString }}</td>
          <td>{{ item.diagnosisOfTheDisease }}</td>
          <td>{{ item.treatmentRecommendations }}</td>
          <td>{{ item.vision }}</td>
          <td>{{ item.hearing }}</td>
          <td>{{ item.medicalPhysicalEduGroup }}</td>
          <td>{{ item.height }}</td>
          <td>{{ item.deskNumber }}</td>
          <td>
            <b-button
                type="submit"
                @click="edit(item)"
                variant="primary"
            >
              {{ $t("common.edit") }}
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        <b-modal id="informationAboutHeath-modal" ref="informationAboutHeath-modal">
          <template v-slot:modal-title>
            {{ $t("common.new") }}
          </template>
          <b-form>
            <div>
              {{ $t("informationAboutHeath.list.name") }} - {{form.studentName}}
            </div>
            <div>
              {{ $t("informationAboutHeath.list.birthDate") }} - {{form.birthDateString}}
            </div>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutHeath.list.diagnosisOfTheDisease") }}
              </template>
              <b-form-input
                  v-model="form.diagnosisOfTheDisease"
                  :state="modelState.diagnosisOfTheDisease"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.diagnosisOfTheDisease }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutHeath.list.treatmentRecommendations") }}
              </template>
              <b-form-input
                  v-model="form.treatmentRecommendations"
                  :state="modelState.treatmentRecommendations"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.treatmentRecommendations }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutHeath.list.vision") }}
              </template>
              <b-form-input
                  v-model="form.vision"
                  :state="modelState.vision"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.vision }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutHeath.list.hearing") }}
              </template>
              <b-form-input
                  v-model="form.hearing"
                  :state="modelState.hearing"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.hearing }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutHeath.list.medicalPhysicalEduGroup") }}
              </template>
              <b-form-input
                  v-model="form.medicalPhysicalEduGroup"
                  :state="modelState.medicalPhysicalEduGroup"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.medicalPhysicalEduGroup }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutHeath.list.height") }}
              </template>
              <b-form-input
                  v-model="form.height"
                  :state="modelState.height"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.height }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutHeath.list.deskNumber") }}
              </template>
              <b-form-input
                  v-model="form.deskNumber"
                  :state="modelState.deskNumber"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.deskNumber }}</div>
            </b-form-group>
          </b-form>          

          <template v-slot:modal-footer>
            <b-button type="submit" @click="onSubmit" variant="primary" id="save-subject-data-button">
              {{ $t("common.save") }}
            </b-button>
            <b-button type="reset" @click="onReset" variant="danger">
              {{ $t("common.cancel") }}
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "InformationAboutHeath",
  components: {
  },
  props:{
    groupId:String
  },
  data() {
    return {
      modalName: "informationAboutHeath-modal",
      items: [],
      form: {
        studentId: 0,
        studentName: "",
        diagnosisOfTheDisease: "",
        treatmentRecommendations: "",
        vision: "",
        hearing: "",
        medicalPhysicalEduGroup: "",
        height: "",
        deskNumber: "",
      },
      modelState: {},
      modelSaveErrors: {},
      groupIdMut: 0,
    };
  },
  mounted() {
    this.groupIdMut = this.groupId;
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      let submitButton = document.getElementById("save-subject-data-button");
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      
      evt.preventDefault();
      let $this = this;
      this.form.groupId = this.groupId;

      ApiService.postSecured("journal/additional/saveInformationAboutHeath", this.form)
          .then(function () {
            $this.$refs[$this.modalName].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveErrors = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;
              }
            }else{
              alert("Ошибка, перезайдите или обратитесь к администратору");
            }
          }).finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs[this.modalName].hide();
    },
    reloadData(groupId) {
      if(typeof groupId !== "undefined"){
        this.groupIdMut = groupId
      }
      
      ApiService.querySecured("journal/additional/informationAboutHeath", {
        params: {groupId: this.groupIdMut}
      }).then(({data}) => {
        this.items = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    edit(item) {
      this.form = item;
      this.$refs[this.modalName].show();
    }
  }
};
</script>
